<template>
  <div class="products-detail-container">
    <div class="top-products">
      <span>Top 3 Ürün</span>
      <div class="items">
        <ProductItem
          v-for="(item, index) in toP3SkuList"
          :key="index"
          :item="item"
          :index="index"
        />
      </div>
    </div>
    <div class="strategic-products">
      <span>Stratejik Ürünler</span>
      <div class="items">
        <ProductItem
          v-for="(item, index) in strategicSkuList"
          :key="index"
          :item="item"
          :index="index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, toRefs } from 'vue';
import ProductItem from './ProductItem.vue';

export default defineComponent({
  components: { ProductItem },
  props: {
    toP3SkuList: {
      type: Array,
      required: true,
    },
    strategicSkuList: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const { toP3SkuList, strategicSkuList } = toRefs(props);

    return {
      toP3SkuList,
      strategicSkuList,
    };
  },
});
</script>

<style scoped lang="scss">
.products-detail-container {
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 0 0;
}

.top-products {
  width: 100%;
  margin-top: 40px;
  span {
    font-size: 18px;
    font-weight: 500;
    margin-left: 20px;
  }
}

.strategic-products {
  width: 100%;
  padding-bottom: 80px;
  span {
    font-size: 18px;
    font-weight: 500;
    margin-left: 20px;
  }
}

.items {
  display: flex;
  justify-content: flex-start;
  margin: 20px;
  overflow-x: scroll;
  height: 300px;
}
</style>
