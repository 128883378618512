<template>
  <div class="item">
    <img :src="item.image" width="45" height="75" style="max-height: 75px;" />
    <div class="item-desc">{{ item.productName }}</div>
    <div class="tooltip">
      <div class="tooltip-arrow"></div>
      <div class="tooltip-row">
        <span>İş Payı</span>
        <div>
          <progress :value="item.workRatio" max="100" class="custom-progress" />
          <span>%{{ item.workRatio }}</span>
        </div>
      </div>
      <div class="divider" />
      <div class="tooltip-row">
        <span>Ytd Değişimi</span>
        <div>
          <progress :value="item.ytdvar" max="100" class="custom-progress" />
          <span>%{{ item.ytdvar }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, toRefs } from 'vue';

export default defineComponent({
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { item, index } = toRefs(props);

    return {
      item,
      index,
    };
  },
});
</script>

<style scoped lang="scss">
.item {
  width: auto;
  height: 160px;
  min-width: 145px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  border-radius: 5px;
  margin-left: 16px;
  border: 1px solid #ededed;
}

.item-desc {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  margin-top: 15px;
}

.tooltip {
  top: 105%;
  left: 50%;
  opacity: 0;
  z-index: 1;
  display: flex;
  width: 260px;
  height: 115px;
  border-radius: 5px;
  padding: 0 15px;
  position: absolute;
  margin-left: 50px;
  visibility: hidden;
  flex-direction: column;
  justify-content: center;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ececec;
  transition: opacity 0.2s, visibility 0.2s;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.tooltip-arrow {
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: #fff;
}

.tooltip-content {
  padding: 25px 10px;
}

.tooltip-row {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    font-size: 14px;
    margin-left: 5px;
    font-weight: 500;
  }
}

.divider {
  height: 1px;
  margin: 10px;
  background-color: rgba(180, 194, 211, 0.2);
}

.custom-progress {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-top: 10px;
  width: 70px;
  height: 8px;
}

.custom-progress::-webkit-progress-bar {
  background-color: #e0e0e0;
  border-radius: 5px;
}

.custom-progress::-webkit-progress-value {
  background: linear-gradient(to left, #d20051 0%, #ffa300 100%);
  border-radius: 5px;
}

.custom-progress::-moz-progress-bar {
  background: linear-gradient(to left, #d20051 0%, #ffa300 100%);
  border-radius: 5px;
}

.custom-progress::-ms-fill {
  background: linear-gradient(to left, #d20051 0%, #ffa300 100%);
  border-radius: 5px;
}

.item:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.item:hover {
  border: 1px solid #d20051;
}
</style>
